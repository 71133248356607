import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {
    Container,
    Typography,
    TextField,
    Grid,
    Divider,
    Box,
    MenuItem,
    IconButton,
    Badge,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import JobsDialog from "../dialog/JobsDialog";
import Card from "@material-ui/core/Card";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import JobViewDialog from "../dialog/job view/JobViewDialog";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import Rating from "@material-ui/lab/Rating";
import {Pagination} from "pagination-ui";
import {JobSkeletonUse} from "../components/SkeletonUse";
// import song from "../bell/a.mp3";
import {socket} from "../services/socket";
import AddButton from "../components/AddButton";
import JobCreate from "../dialog/JobCreate";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DownloadCSV from "../utils/DownloadCSV";
import CsvGenerateModal from "../dialog/CsvGenerateModal";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "20px",
    },
    blink: {
        animation: `$blink 4s infinite`,
    },
    '@keyframes blink': {
        '0%': {
            opacity: 1
        },
        '25%': {
            opacity: 0
        },
        '50%': {
            opacity: 1
        },
        '75%': {
            opacity: 0
        },
        '100%': {
            opacity: 1
        },
    },
    divChange: {
        backgroundColor: "#fdf1f4",
    }
}));

export default function Jobs(props) {
    const userInfo = JSON.parse(localStorage.getItem("user-info"))?.user
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [assign, setAssign] = useState(null);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [jobViewModal, setJobViewModal] = useState(null);
    const [jobCreate, setJobCreate] = useState(null);
    const [statusCount, setStatusCount] = useState({});
    // const [music, setMusic] = useState(new Audio(song));
    const [csvModalData, setModalCsvData] = useState({
        isVisible: false,
        data: {}
    });

    // const [selectedValue, setSelectedValue] = useState('');
    // const handleSelected = (e) => {
    //
    //
    // }
    // console.log(statusCount,"==========statusCount")

    // const music = {
    //     audio:new Audio(song)
    // }
    // const [state, setState] = ({
    //     audio: new Audio(song),
    //     isPlaying: false,
    // });

    const setFilter = (key, value) => {
        setFilters((prev) => {
            return {
                ...prev,
                [key]: value === "any" ? "" : value,
            };
        });
    };
    const retrieveJobs = async () => {
        try {
            setLoading(true);
            const {
                data: {orders, total, statusCount},
            } = await crud.get("retrieve/web/orders", {
                ...filters,
                limit,
                page,
            });
            setOrders(orders);
            setTotal(total);
            setStatusCount(statusCount)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        socket.on("web_reviews", (order) => {
            setOrders((prev) => {
                const temp = [...prev];
                const exist = temp.find((s) => s.id === order?.orderData?.id);
                exist.rating = order?.orderData?.rating;
                return temp;
            });
        });
        return () => {
            socket.off("web_reviews")
        };
    }, []);

    useEffect(() => {
        socket.on("cancel_order", (order) => {
            console.log(order);
            setOrders((prev) => {
                const temp = [...prev];
                const exist = temp.find((s) => s.id === order?.order?.id);
                exist.status = order?.order?.status;
                return temp;
            });
        });
        return () => {
            socket.off("cancel_order")
        };
    }, []);

    useEffect(() => {
        socket.on("accept_job_web", (acceptJob) => {
            console.log(acceptJob);
            setOrders((prev) => {
                const temp = [...prev];
                const exist = temp?.find((s) => s.id === acceptJob?.order?.id);
                exist.status = acceptJob?.order?.status;
                exist.professional = acceptJob?.order?.professional;
                return temp;
            });
        });
        return () => {
            socket.off("accept_job_web")
        };
    }, []);

    useEffect(() => {
        socket.on("web_reject_job", (rejectJob) => {
            setOrders((prev) => {
                const temp = [...prev];
                const exist = temp.find((s) => s.id === rejectJob?.orderData?.id);
                exist.status = rejectJob?.orderData?.status;
                exist.professional = rejectJob?.orderData?.professional;
                return temp;
            });
        });
        return () => {
            socket.off("web_reject_job")
        };
    }, []);

    useEffect(() => {
        socket.on("web-order", (data) => {
            setOrders((prev) => {
                return [data.orderData, ...prev];
            });
        });
        return () => {
            socket.off("web-order")
        };
    }, []);

    // useEffect(() => {
    //   socket.on("cancel_order", (data) => {
    //     setOrders((prev) => {
    //       const temp = [...prev];
    //       const exist = temp.find((s) => s.id === data?.id);
    //       exist.status = data?.status;
    //       return temp;
    //     });
    //   });
    //   return () => {socket.off("cancel_order")};
    // }, []);

    useEffect(() => {
        retrieveJobs();
    }, [filters, limit, page]);

    const classes = useStyles();

    // console.log(filters)

    const statusLabels = {
        any: 'All',
        "Not InProgress": 'Not Accepted',
        pending: 'Pending',
        accepted: 'Accepted',
        completed: 'Completed',
        cancelled: 'Cancelled'
    };

    const cities = ["Agra", "Mathura", "Ahmedabad","Ferozabad","Gandhinagar"]

    return (
        <>
            <Appbar/>
            <Drawer/>
            <Container
                maxWidth="xl"
                style={{marginBottom: "65px", marginTop: "20px"}}
            >

                <Grid container spacing={1} justifyContent={"space-between"}>
                    <Grid item xs={12} md={userInfo?.role == 1 ? 3 : 6}>
                        <Typography variant="h6" className="font-weight-bolder">
                            Jobs
                        </Typography>
                    </Grid>

                    {userInfo?.role == 1 && <Grid item xs={12} sm={3} md={2}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">City</InputLabel>
                            <Select
                                style={{height: "40px", overflow: "hidden"}}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="City"
                                value={filters["city"] || "any"}
                                onChange={(e) => setFilter("city", e.target.value)}
                                // renderValue={(value) => statusLabels[value]}
                            >
                                <MenuItem value="any">All</MenuItem>
                                {cities.map((item, index) => {
                                    return <MenuItem
                                        key={index + item}
                                        value={item ?? ""}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                        {item}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>}
                    <Grid item xs={12} sm={3} md={2}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                            <Select
                                style={{
                                    height: "40px",
                                    // textOverflow: "ellipsis",
                                    // whiteSpace: "nowrap",
                                    overflow: "hidden"
                                }}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Status"
                                value={filters["status"] || "any"}
                                onChange={(e) => setFilter("status", e.target.value)}
                                renderValue={(value) => statusLabels[value]}
                            >
                                <MenuItem value="any">All</MenuItem>
                                <MenuItem value="Not InProgress"
                                          style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center'
                                          }}>
                                    Not Accepted
                                    <Badge badgeContent={statusCount?.notInProgress} color="primary"/>
                                </MenuItem>
                                <MenuItem value="pending"
                                          style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center'
                                          }}>
                                    Pending
                                    <Badge badgeContent={statusCount?.pending} color="primary"/>
                                </MenuItem>
                                <MenuItem value="accepted"
                                          style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center'
                                          }}>Accepted
                                    <Badge badgeContent={+statusCount?.accepted} color="primary"/>
                                </MenuItem>
                                <MenuItem value="completed"
                                          style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center'
                                          }}>Completed
                                    <Badge badgeContent={statusCount?.completed} color="primary"/>
                                </MenuItem>
                                <MenuItem value="cancelled"
                                          style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center'
                                          }}>Cancelled
                                    <Badge badgeContent={+statusCount?.cancelled} color="primary"/>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            variant="outlined"
                            size="small"
                            color="primary"
                            fullWidth
                            placeholder="Mobile Number..."
                            onChange={(e) => setFilter("search", e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary"/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            variant="outlined"
                            size="small"
                            color="primary"
                            fullWidth
                            placeholder="Job Order no..."
                            onChange={(e) => setFilter("orderId", e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary"/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setModalCsvData({...csvModalData, isVisible: true})}
                        >
                            Download CSV
                        </Button>
                    </Grid>
                    <Grid>
                        {/*{console.log((orders[0]))}*/}
                        <DownloadCSV
                            headerString={'#,orderId,category,Sub-cat Name,Customer Name,Customer Mobile No.,ProfessionalId,Professional Name,Paid Amount,Address,Time Slot'}
                            data={orders.map((e, i) => {
                                if (e.status == 'completed') {
                                    const professional = e.job_order_professionals.find((e) => e.status === "completed")
                                    let obj = {
                                        '#': i++,
                                        'orderId': e.id,
                                        'category': e.category.name,
                                        'Sub-cat Name': e.sub_category.name,
                                        'Customer Name': e.customer.name,
                                        'Customer Mobile No.': e.customer.mobile,
                                        'ProfessionalId': professional?.professional?.id,
                                        'Professional Name': professional?.professional?.name,
                                        'Paid Amount': !e?.additional_services?.length ? (e?.total - e?.discount) : e?.additional_services?.reduce((prev, item) =>
                                            Number(prev) + item?.price, [0]) + e?.total - e?.discount,
                                        'Address': `${e.address.address}-${e.address.city}-${e.address.state}-${e.address.pincode}-${e.address.landmark}`,
                                        'Time Slot': `${moment(e.startTime, "HH:mm").format("h:mm A")}-${moment(e.endTime, "HH:mm").format("h:mm A")}`
                                    }
                                    return obj
                                }
                            }).filter((e) => e)}
                            fileName="order"
                        />

                        {/*<FormControl variant="outlined" fullWidth >*/}
                        {/*  <InputLabel id="demo-simple-select-outlined-label">Export</InputLabel>*/}
                        {/*  <Select*/}
                        {/*      style={{height:"40px",*/}
                        {/*        // textOverflow: "ellipsis",*/}
                        {/*        // whiteSpace: "nowrap",*/}
                        {/*        overflow: "hidden" }}*/}
                        {/*      class = "px-5 mx-3"*/}
                        {/*      labelId="demo-simple-select-outlined-label"*/}
                        {/*      id="demo-simple-select-outlined"*/}
                        {/*      label="Age"*/}
                        {/*      className={classes.dropdown}*/}

                        {/*  >*/}
                        {/*    <MenuItem value="CSV">CSV</MenuItem>*/}
                        {/*    <MenuItem value="pdf">pdf</MenuItem>*/}
                        {/*    <MenuItem value={30}>Thirty</MenuItem>*/}
                        {/*  </Select>*/}
                        {/*</FormControl>*/}

                    </Grid>
                </Grid>
                {!loading ? (
                    orders?.map((order, index) => {
                        const totalAmount = order?.total - +order?.discount + Number(order?.additional_services?.reduce((acc, item) => {
                            return Number(acc) + +item.price;
                        }, []))
                        const professionalAmt = ((Number(order?.professional?.revenuePercentage) || 0) * (Number(totalAmount) || 0)) / 100
                        return (<Card className="mt-3">
                                <CardContent>
                                    <div className="d-flex justify-content-between align-items-center" key={index}>
                                        <div>
                                            <Typography variant="h6" className="d-inline header__title">
                                                {order.sub_category?.name} |
                                            </Typography>
                                            <Typography variant="h6" className="d-inline header__title">
                                                &nbsp; {order.category?.name} |
                                            </Typography>
                                            <Typography variant="h6" className="d-inline header__title">
                                                &nbsp; {order?.id} |
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                className="d-inline ml-1"
                                                style={{
                                                    color: "#64B6F7",
                                                    fontSize: "16px",
                                                }}
                                            >
                                                &nbsp;{" "}
                                                <span
                                                    style={{
                                                        background:
                                                            order?.status === "not assigned" ?
                                                                "#DFCF14"
                                                                : order?.status === "pending"
                                                                ? "#DFCF14"
                                                                : order?.status === "accepted"
                                                                    ? "#5fdba7"
                                                                    : order?.status === "rejected"
                                                                        ? "red"
                                                                        : order?.status === "completed"
                                                                            ? "#3cb1a0"
                                                                            : "#E466a3",
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "12px",
                                                        color: "#FFFFFF",
                                                        textTransform: "capitalize",
                                                    }}
                                                    className={order?.status === "Not InProgress" ? classes.blink : ""}
                                                >{order?.status === "not assigned" ? "pending" : order?.status === "Not InProgress" ? 'Not Accepted' : order?.status}</span>
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography
                                                variant="body1"
                                                className="font-weight-bold d-inline ml-5 text-success"
                                            >
                                                Accepted By :
                                                <span
                                                    style={{
                                                        color: "#000",
                                                        fontWeight: "400",
                                                    }}
                                                >{order.professional?.name}</span>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Typography
                                                variant="body1"
                                                className="font-weight-bold d-inline text-success"
                                            >
                                                Order Date :
                                                <span
                                                    style={{
                                                        color: "#000",
                                                        fontWeight: "400",
                                                    }}
                                                >&nbsp;{moment(order?.createdAt).format(crud.dateFormat)}</span>
                                            </Typography>
                                        </div>
                                        <div>
                                            <Box className="font-weight-bold d-inline text-success">
                                                <Rating
                                                    name="read-only"
                                                    value={order?.rating || 0}
                                                    readOnly
                                                />
                                            </Box>

                                            <Box className="float-right">
                                                <IconButton
                                                    onClick={() => setJobViewModal(order)}
                                                    color="primary">
                                                    <VisibilityIcon/>
                                                </IconButton>
                                            </Box>
                                        </div>
                                    </div>
                                    <Divider className="mt-2 mb-2"/>
                                    <Box className="d-flex justify-content-between align-items-center">
                                        <Typography variant="caption" style={{fontSize: "12px"}}>
                                            {order?.address?.address},{order?.address?.landmark},<br/>
                                            {order?.address?.city},{order?.address?.state}
                                        </Typography>
                                        <Box>
                                            <Typography variant="h6" color={"primary"} style={{fontSize: "16px", fontWeight:"700"}}>
                                                Expert Amount: ₹ {totalAmount - professionalAmt}
                                            </Typography>
                                            <Typography variant="h6" color={"primary"} style={{fontSize: "14px"}}>
                                                S4H Amount: ₹ {professionalAmt}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                style={{fontSize: "14px", display: "inline"}}
                                            >
                                                Total&nbsp;Price: ₹{totalAmount}/-
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider className="mt-1 mb-2"/>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            color: "white",
                                            fontSize: "10px",
                                            paddingBottom: "1.2px",
                                        }}

                                        disabled={
                                            order?.status === "completed" ||
                                            // order?.status === "pending" ||
                                            order?.status === "accepted"
                                                ? true
                                                : false
                                        }
                                        size="small"
                                        onClick={() => {
                                            // console.log({order})
                                            setAssign(order);
                                        }}
                                    >
                                        Job Assign
                                    </Button>

                                    <Typography
                                        variant="caption"
                                        className="font-weight-bold"
                                        style={{fontSize: "13px"}}
                                    >
                                        &nbsp;Assign To :
                                    </Typography>
                                    <Typography variant="caption" style={{fontSize: "12px"}}>
                                        &nbsp;
                                        {order.job_order_professionals
                                            ?.map((item) => item.professional?.name)
                                            .join(" , ")}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        className="float-right font-weight-bolder"
                                        style={{fontSize: "14px"}}
                                        color="primary"
                                    >
                                     <span className="font-weight-bold text-black-50">
                                   Assign By:&nbsp; {order?.assign_by?.name}
                                      </span>
                                    </Typography>
                                    <Divider variant="middle"/>
                                    <Typography
                                        variant="subtitle1"
                                        className="float-right font-weight-bolder"
                                        style={{fontSize: "14px"}}
                                        color="primary"
                                    >
                                     <span className="font-weight-bold text-black-50">
                                   Remark:&nbsp; {order?.remark}
                                      </span>
                                        {/*<Divider/>*/}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )
                    })
                ) : (
                    <JobSkeletonUse limit={5} cellCount={1}/>
                )}

                {!orders.length && !loading && (
                    <Typography className="p-2 mt-5 w-100 text-center font-weight-bold">
                        No records found
                    </Typography>
                )}
                <Pagination
                    limit={limit}
                    limitRange
                    total={total}
                    page={page}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                />
            </Container>

            <IconButton onClick={() => {
                setJobCreate(true)
            }}>
                <AddButton/>
            </IconButton>
            <JobViewDialog
                order={jobViewModal}
                onClose={() => setJobViewModal(null)}
                open={!!jobViewModal}
            />
            <JobCreate
                order={jobCreate}
                onClose={() => setJobCreate(null)}
                open={!!jobCreate}
                onSave={retrieveJobs}
            />

            {!!assign && <JobsDialog
                open={!!assign}
                onClose={() => setAssign(null)}
                assign={assign}
                onSave={retrieveJobs}
            />}
            <CsvGenerateModal
                isVisible={csvModalData.isVisible}
                onClose={() => setModalCsvData({...csvModalData, isVisible: false})}
                // onClose={()=> self.registration.showNotification('jhgcsd',
                //     {body:'asdguy'})}


            />

            <Footer/>
        </>
    );
}
